<div class="text-center px-4 py-8 md:px-6 lg:px-8">
  <steps-router></steps-router>
  <div class="gird">
    <div class="col">

    </div>
  </div>
  <div class="text-900 font-medium text-2xl mb-3">Wybierz lokalizację:</div>
  <div class="text-700 line-height-3 mb-4">
    Volutpat est velit egestas dui id ornare arcu odio. Amet dictum sit amet justo.
  </div>
  <div class="grid align-items-stretch">
    @for(availableLocation of locations; track 'location_' + availableLocation.id) {
      <div class="col-12 md:col-6 lg:col-4 xl:col-3">
        <div class="shadow-1 border-round border-2 cursor-pointer surface-card h-fit"
          [ngClass]="{
            'border-transparent': selectedLocation?.id !== this.locations[$index].id,
            'border-blue-500': selectedLocation?.id === this.locations[$index].id,
            'shadow-3': selectedLocation?.id === this.locations[$index].id
          }" (click)="selectLocation($event, $index);">
          <img [alt]="'tl-' + availableLocation.id" src="{{availableLocation.thumbnail?.url}}" class="w-full" />
          <div class="p-3 flex flex-column align-items-center gap-3">
            <div class="text-900 text-xl font-medium">{{availableLocation.name}}</div>
            @if (availableLocation.place) {
              <p class="m-0" [innerHTML]="Address.printAddress(availableLocation.place) | sanitizeHtml"></p>
            } @else {
              <p class="m-0">-</p>
            }
            <!--  @ToDo: określić prawidłowe value, nie zaznacza na wejściu/powrocie do widoku z sessionStorage  -->
            <p-radioButton name="location" [value]="this.locations[$index].id" [ngModel]="selectedLocation?.id" [ngModelOptions]="{standalone: true}"></p-radioButton>
          </div>
          <div class="py-2"></div>
        </div>
        @if (availableLocation.promotions.length > 0) {

          @for(promotion of availableLocation.promotions; track 'loc_promo_' + promotion.id) {
            <div class="text-gray-50 mx-2 p-3 flex lg:justify-content-center align-items-center flex-wrap"
                 [ngStyle]="{'background-color': promotion.backgroundColor}">
              <div class="font-bold mr-8">{{promotion.icon}} {{promotion.title}}</div>
              <div class="align-items-center hidden lg:flex">
                <span class="line-height-3">{{promotion.description }}</span>
              </div>
              <div class="flex align-items-center ml-2 mr-8">
                <a class="text-white" href="{{promotion.detailsLink}}"><span class="underline font-bold">Sprawdź szczegóły</span></a>
              </div>
            </div>
          }

        }
      </div>
    }
  </div>
  <div class="grid flex py-3 sm:py-4">
    <div class="col-12 lg:col-12">
      <span class="p-buttonset">
        <p-button label="Wstecz" icon="pi pi-angle-left" iconPos="left"
          *ngIf="this.currentView !== '/creator'"></p-button>
        <p-button label="Następny" icon="pi pi-angle-right" iconPos="right" [disabled]="!this.selectedLocation"
          (click)="startCart();"></p-button>
      </span>
    </div>
  </div>
</div>

<p-dialog [(visible)]="locked" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}"
          [closable]="true" [showHeader]="true" [draggable]="false" [closeOnEscape]="true" (onHide)="this.locked = false; this.selectedLocation = null;">
  <ng-template pTemplate="header">
    <div class="w-full"></div>
  </ng-template>
  <div class="flex flex-column align-items-center my-4 w-full">
    <span class="flex align-items-center justify-content-center bg-blue-100 text-blue-800 border-circle mb-3"
          style="width:64px;height:64px">
      <i class="fa-solid fa-info text-5xl"></i>
    </span>
    <div class="font-medium text-2xl text-900">Ojj!</div>
  </div>
  <p class="line-height-3 p-0 mx-0 my-1 text-justify">
    Dziękujemy za wybranie tej lokalizacji.
  </p>
  <p class="line-height-3 p-0 mx-0 my-1 text-justify">
    Niestety, ze względu na duże zainteresowanie, nie możesz wybrać tej lokalizacji.
  </p>
  <p class="line-height-3 p-0 mx-0 my-1 text-justify">
    Nic straconego, skontaktuj się z Nami, a pomożemy Ci dokonać rezerwacji w tej lokalizacji.
  </p>
  <p class="line-height-3 p-0 mx-0 my-1 align-items-center text-center">

  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3 flex justify-content-center">
      <a pButton class="font-medium mr-4 cursor-pointer select-none transition-colors transition-duration-150 no-underline"
         href="mailto:biuro@przechowalnia24.pl?subject=Potrzebuję kontenera, Lokalizacja: {{ this.selectedLocation?.name }}"
         (click)="this.locked = false; this.selectedLocation = null;"
      >
        <i class="fa-solid fa-paper-plane mr-1"></i> Napisz e-mail
      </a>
      <a pButton class="font-medium cursor-pointer select-none transition-colors transition-duration-150 no-underline"
         href="tel:+48883839389" (click)="this.locked = false; this.selectedLocation = null;">
        <i class="fa-solid fa-phone-volume mr-1"></i> Zadzwoń
      </a>
      <!--<button pButton pRipple (click)="this.locked = false; this.selectedLocation = null;" label="OK" class="p-button-outlined w-6 mr-2">
      </button>-->
    </div>
  </ng-template>
</p-dialog>
