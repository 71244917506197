import { Component } from '@angular/core';
import { AppConfig, LayoutService } from "../services/layout.service";

@Component({
  selector: 'panel-layout',
  templateUrl: './panel-layout.component.html',
  styleUrl: './panel-layout.component.scss'
})
export class PanelLayoutComponent {

  config!: AppConfig;
  subscription: any;
  visible1: boolean;
  value1: string = '';

  constructor(public layoutService: LayoutService) {
    this.subscription = this.layoutService.configUpdate$.subscribe(
      (config) => {
        this.config = config;
      }
    );
    this.visible1 = false;
  }
  get containerClass() {
    let styleClass: { [key: string]: any } = {
      'layout-dark': this.layoutService.config().colorScheme === 'dark',
      'layout-dim': this.layoutService.config().colorScheme === 'dim',
      'layout-light': this.layoutService.config().colorScheme === 'light',
      'layout-overlay':
        this.layoutService.config().menuMode === 'overlay',
      'layout-static': this.layoutService.config().menuMode === 'static',
      'layout-horizontal':
        this.layoutService.config().menuMode === 'horizontal',
      'p-input-filled':
        this.layoutService.config().inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config().ripple,
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config().menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active':
      this.layoutService.state.staticMenuMobileActive,
    };

    styleClass['layout-topbar-' + this.layoutService.config().topbarTheme] =
      true;
    styleClass['layout-menu-' + this.layoutService.config().menuTheme] =
      true;
    return styleClass;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

}
