import { Injectable } from '@angular/core';
import { Location } from "../models/location";
import { BehaviorSubject, catchError, Observable } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class CreatorService {

  private itemSource = new BehaviorSubject<string | null>(this.getSessionStorageItem('key'));
  item$ = this.itemSource.asObservable();

  constructor(private readonly http: HttpClient) {}

  setSessionStorageItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
    this.itemSource.next(value);
  }

  getSessionStorageItem(key: string, defaultValue: any = null): any | null {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  }

  clearSessionStorageItem(key: string): void {
    sessionStorage.removeItem(key);
    this.itemSource.next(null);
  }

  clearSessionStorage() {
    sessionStorage.clear();
    this.itemSource.next(null);
  }

  startReservation(location: Location) {
    return this.http.post('/api/reservation', {
      locationId: location.id,
    });
  }

  saveReservation(data: any) {
    return this.http.put(`/api/reservation/${data.reservation.id}`, data);
  }

  addToReservation(data: any) {
    return this.http.post(`/api/reservation/${data.reservation.id}/details`, data);
  }

  updateReservationDetails(data: any) {
    console.log("updateReservationDetails");
    console.log(data);
    return this.http.patch(`/api/reservation/${data.reservation.id}/details`, data);
  }

  deleteFromReservation(data: any) {
    return this.http.delete(`/api/reservation/${data.reservation.id}/details/${data.itemId}`);
  }

  saveForm(formId: number, data: any) {
    const reservationSession = this.getSessionStorageItem('reservationSession', []);
    if (reservationSession.id && !formId) {
      return this.http.post(`/api/reservation/${reservationSession.id}/form`, data).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return new Observable<Object>(); // TODO: Obsługa błędów walidacji.
          }
          return new Observable<Object>();
        }),
      );
    } else if (reservationSession.id && formId) {
      return this.http.put(`/api/reservation/${reservationSession.id}/form/${formId}`, data).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return new Observable<Object>(); // TODO: Obsługa błędów walidacji.
          }
          return new Observable<Object>();
        }),
      );
    }
    return new Observable<Object>();
  }

  finishReservation(formId: number, data: any) {
    const reservationSession = this.getSessionStorageItem('reservationSession', []);
    console.log("finishReservation formId");
    console.log(reservationSession);
    console.log(formId);
    if (reservationSession.id && formId) {
      return this.http.patch(`/api/reservation/${reservationSession.id}/form/${formId}`, data)
        .pipe(catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return new Observable<Object>(); // TODO: Obsługa błędów walidacji.
          }
          return new Observable<Object>();
        }))
        ;
    }
    return new Observable<Object>();
  }

  getReservation(id: number) {
    return this.http.get(`/api/reservation/${id}`);
  }

  removeFromCart(data: any) {
    return this.http.delete(`/api/reservation/${data.reservation.id}/details/${data.itemId}`);
  }

  removeAll(data: any) {
    return this.http.delete(`/api/reservation/${data.reservation.id}/details`);
  }

  createStorageContainer(data: any) {
    return this.http.post('/api/storageContainers', data);
  }

  saveStorageContainer(id: number, data: any) {
    return this.http.put(`/api/storageContainers/${id}`, data);
  }

  deleteStorageContainer(id: number) {
    return this.http.delete(`/api/storageContainers/${id}`);
  }

  saveReservedContainer(reservationId: number, containers: any[]) {
    return this.http.patch(`/api/reservation/${reservationId}`, { storageContainers: containers });
  }

  updateReservation(reservationId: number, data: any) {
    return this.http.patch(`/api/reservation/${reservationId}`, data);
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post('/api/files', formData, {
    });
  }

  public getMonthString(monthCount: number): string {
    const lastDigit = monthCount % 10;
    const penultimateDigit = monthCount % 100;

    if (monthCount === 1) {
      return `miesiąc`;
    } else if (lastDigit >= 2 && lastDigit <= 4 && (penultimateDigit < 10 || penultimateDigit >= 20)) {
      return `miesiące`;
    } else {
      return `miesięcy`;
    }
  }
}
