<div class="h-full" [ngClass]="containerClass">

  <div class="min-h-screen flex surface-ground">
    <div id="app-sidebar-1" class="surface-section h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 border-right-1 surface-border select-none z-5" style="width: 270px;">
      <div class="flex flex-column h-full">
        <div class="flex align-items-center px-1 md:px-0 flex-shrink-0" style="height:60px">
          <img ngSrc="assets/images/self-storage-przechowalnia24-logo.png" alt="Image" height="50" width="258">
        </div>
        <div class="overflow-y-auto">
          <!--<ul class="list-none p-3 m-0">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer" pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
                <span class="font-medium">FAVORITES</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-home mr-2"></i>
                    <span class="font-medium">Dashboard</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-bookmark mr-2"></i>
                    <span class="font-medium">Bookmarks</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline"
                     pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
                    <i class="pi pi-chart-line mr-2"></i>
                    <span class="font-medium">Reports</span>
                    <i class="pi pi-chevron-down ml-auto"></i>
                  </a>
                  <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li>
                      <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline"
                         pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
                        <i class="pi pi-chart-line mr-2"></i>
                        <span class="font-medium">Revenue</span>
                        <i class="pi pi-chevron-down ml-auto"></i>
                      </a>
                      <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                        <li>
                          <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                            <i class="pi pi-table mr-2"></i>
                            <span class="font-medium">View</span>
                          </a>
                        </li>
                        <li>
                          <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                            <i class="pi pi-search mr-2"></i>
                            <span class="font-medium">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                        <i class="pi pi-chart-line mr-2"></i>
                        <span class="font-medium">Expenses</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-users mr-2"></i>
                    <span class="font-medium">Team</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-comments mr-2"></i>
                    <span class="font-medium">Messages</span>
                    <span class="inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle" style="min-width: 1.5rem; height: 1.5rem">3</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-calendar mr-2"></i>
                    <span class="font-medium">Calendar</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-cog mr-2"></i>
                    <span class="font-medium">Settings</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="list-none p-3 m-0">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer" pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
                <span class="font-medium">APPLICATION</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-folder mr-2"></i>
                    <span class="font-medium">Projects</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-chart-bar mr-2"></i>
                    <span class="font-medium">Performance</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="pi pi-cog mr-2"></i>
                    <span class="font-medium">Settings</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>-->
          <ul class="list-none px-2 py-1 mx-0 my-1 overflow-hidden">
            <li>
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 surface-hover hover:surface-200 transition-duration-150 transition-colors no-underline" routerLink="/panel">
                <i class="fa-solid fa-desktop mr-2"></i>
                <span class="font-medium">Pulpit</span>
              </a>
            </li>
          </ul>
          <ul class="list-none px-3 py-2 m-0">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">Kreator</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple
                     class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline" routerLink="/panel/reservations">
                    <i class="fa-solid fa-cart-arrow-down mr-2"></i>
                    <span class="font-medium">Rezerwacje</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-money-bill-transfer mr-2"></i>
                    <span class="font-medium">Transakcje płatnicze</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-people-group mr-2"></i>
                    <span class="font-medium">Klienci</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-warehouse mr-2"></i>
                    <span class="font-medium">Magazyny klientów</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="list-none px-3 py-2 m-0">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">Zaplecze</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline" routerLink="/panel/locations">
                    <i class="fa-solid fa-location-dot mr-2"></i>
                    <span class="font-medium">Lokalizacje</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline" routerLink="/panel/storages">
                    <i class="fa-solid fa-cubes mr-2"></i>
                    <span class="font-medium">Grupy magazynów</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline" routerLink="/panel/storageContainers">
                    <i class="fa-solid fa-cube mr-2"></i>
                    <span class="font-medium">Magazyny</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="list-none px-3 py-2 m-0">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">Ustawienia aplikacji</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-envelope mr-2"></i>
                    <span class="font-medium">Komunikacja</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-users-gear mr-2"></i>
                    <span class="font-medium">Uprawnienia</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-credit-card mr-2"></i>
                    <span class="font-medium">Płatności</span>
                  </a>
                </li>
                <li>
                  <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors no-underline">
                    <i class="fa-solid fa-screwdriver-wrench mr-2"></i>
                    <span class="font-medium">Narzędzia</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="mt-auto">
          <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
          <div class="flex mx-4 md:mx-1 align-items-center justify-content-around">
            <label for="version">Wersja: </label>
            <p-button class="p-0" id="version" label="0.0.0" [link]="true"></p-button>
            <span> | </span>
            <p-button class="p-0 hover:text-orange-300" id="postBug"[link]="true">
              <i class="text-orange-500 hover:text-orange-300 fa-solid fa-bug"></i>
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="min-h-screen max-w-screen flex flex-column relative flex-auto w-9">
      <div class="sticky top-0 z-4">
        <div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border"
             style="height:60px">
          <div class="flex">
            <a pRipple
               class="cursor-pointer inline-flex align-items-center justify-content-center lg:hidden text-700 mr-3"
               pStyleClass="#app-sidebar-1" enterFromClass="hidden" enterActiveClass="fadeinleft" leaveToClass="hidden"
               leaveActiveClass="fadeoutleft" [hideOnOutsideClick]="true">
              <i class="pi pi-bars text-4xl"></i>
            </a>
            <!--<span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" pInputText class="border-none w-10rem sm:w-20rem" placeholder="Search">
            </span>-->
          </div>
          <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterFromClass="hidden"
             enterActiveClass="fadein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
            <i class="pi pi-ellipsis-v text-2xl"></i>
          </a>
          <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
              surface-section border-1 lg:border-none surface-border right-0 top-100 shadow-2 lg:shadow-none absolute lg:static">
            <!--<li>
              <a pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer transition-duration-150 transition-colors no-underline">
                <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                <span class="block lg:hidden font-medium">Inbox</span>
              </a>
            </li>-->
            <li>
              <section class="w-24rem md:w-30rem mx-3 my-2 lg:mx-2 lg:my-1 border-1 surface-border border-round-lg">
                <div class="flex w-full align-items-center justify-content-between px-1">
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-search text-500"></i>
                    <input type="text" pInputText placeholder="Szukaj zamówienia, magazynu, klienta i inne..."
                           class="w-full border-none shadow-none outline-none" (click)="visible1 = true" />
                </span>
                  <span class="border-1 p-1 surface-border border-round surface-100 mr-1 text-600 text-xs hidden md:block w-4rem text-center">
                  Alt + S
                </span>
                </div>
              </section>
            </li>
            <li>
              <a pRipple
                 class="flex p-4 lg:p-3 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer transition-duration-150 transition-colors no-underline">
                <i class="fa-regular fa-bell text-base text-3xl lg:text-2xl mr-2 lg:mr-0" pBadge value="1" severity="danger"></i>
                <span class="block lg:hidden font-medium">Powiadomienia</span>
              </a>
            </li>
            <li class="border-top-1 surface-border lg:border-top-none">
              <a pRipple class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-200 font-medium border-round cursor-pointer
                      transition-duration-150 transition-colors no-underline">
                <img src="https://blocks.primeng.org/assets/images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0"
                     style="width: 32px; height: 32px" />
                <div class="block lg:hidden">
                  <div class="text-900 font-medium">Josephine Lillard</div>
                  <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border"
             style="height:auto">
          <ul class="list-none p-0 m-0 surface-card flex font-medium overflow-y-hidden overflow-x-auto">
            <li class="relative p-3">
              <a class="cursor-pointer">
                <i class="pi pi-home text-blue-500"></i>
              </a>
            </li>
            <li class="relative p-3">
              <div class="absolute left-0 top-0 z-1" style="border-left: 20px solid var(--surface-card); border-top: 26px solid transparent; border-bottom: 26px solid transparent; width: 0; height: 0"></div>
              <a class="cursor-pointer text-blue-500 pl-4 white-space-nowrap">Level 3</a>
              <div class="absolute top-0" style="left: 1px; border-left: 20px solid var(--surface-border); border-top: 26px solid transparent; border-bottom: 26px solid transparent; width: 0; height: 0"></div>
            </li>
            <li class="relative p-3">
              <div class="absolute left-0 top-0 z-1" style="border-left: 20px solid var(--surface-card); border-top: 26px solid transparent; border-bottom: 26px solid transparent; width: 0; height: 0"></div>
              <a class="cursor-pointer text-blue-500 pl-4 white-space-nowrap">Level 2</a>
              <div class="absolute top-0" style="left: 1px; border-left: 20px solid var(--surface-border); border-top: 26px solid transparent; border-bottom: 26px solid transparent; width: 0; height: 0"></div>
            </li>
            <li class="relative p-3">
              <div class="absolute left-0 top-0 z-1" style="border-left: 20px solid var(--surface-card); border-top: 26px solid transparent; border-bottom: 26px solid transparent; width: 0; height: 0"></div>
              <a class="cursor-pointer text-900 pl-4 white-space-nowrap">Level 1</a>
              <div class="absolute top-0" style="left: 1px; border-left: 20px solid var(--surface-border); border-top: 26px solid transparent; border-bottom: 26px solid transparent; width: 0; height: 0"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-column flex-auto">
        <div class="flex-auto">
          <div class="surface-ground">
            <router-outlet></router-outlet>
          </div>
          <p-dialog [(visible)]="visible1" [dismissableMask]="true" [modal]="true" [showHeader]="false"
                    [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                    [style]="{'width': '50vw', 'max-width': '750px'}" contentStyleClass="border-round-top p-0"
                    appendTo="body" (onShow)="input1.focus();" (onHide)="visible1 = false;  value1 = '';">
            <div class="flex w-full surface-section align-items-center justify-content-between px-1">
              <span class="p-input-icon-left w-full">
                  <i class="pi pi-search text-500"></i>
                  <input #input1 type="text" pInputText placeholder="Search menus, shortcuts, contact and more..."
                         [(ngModel)]="value1" class="w-full border-none shadow-none outline-none" />
              </span>
              <span class="border-1 p-1 surface-border border-round surface-100 mr-1 text-600 text-xs text-center hidden md:block w-4rem">
                Alt + S
              </span>
            </div>
            <div class="border-y-1 surface-border px-3 py-3">
              <h2 class="font-semibold text-xs text-500 mb-2 mt-0 px-1">OSTATNIE</h2>
              <ul class="list-none m-0 p-0">
                <li
                  class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover hover:text-primary">
                  unread mails
                </li>
                <li
                  class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover hover:text-primary">
                  go to last mail
                </li>
                <li
                  class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover hover:text-primary">
                  lock account
                </li>
                <li
                  class="select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover hover:text-primary">
                  profile
                </li>
              </ul>
            </div>
            <div class="border-y-1 surface-border px-3 py-3">
              <h2 class="font-semibold text-xs text-500 mb-2 mt-0 px-1">LEGENDA</h2>
              <ul class="list-none m-0 p-0">
                <li
                  class="select-none p-2 font-medium text-base text-gray-400 cursor-pointer border-round-lg hover:surface-hover hover:text-color-secondary">
                  Przejdź do zamówienia wpisując: zm#&#123;nr_zamówienia&#125;,<br/> np. zm#451
                </li>
                <li
                  class="select-none p-2 font-medium text-base text-gray-400 cursor-pointer border-round-lg hover:surface-hover hover:text-color-secondary">
                  Przejdź do użytkownika wpisując: uz#&#123;login&#125;, uz#&#123;email&#125;,<br/> np. uz#k_024 / uz#test&#64;d.pl
                </li>
                <li
                  class="select-none p-2 font-medium text-base text-gray-400 cursor-pointer border-round-lg hover:surface-hover hover:text-color-secondary">
                  lock account
                </li>
                <li
                  class="select-none p-2 font-medium text-base text-gray-400 cursor-pointer border-round-lg hover:surface-hover hover:text-color-secondary">
                  profile
                </li>
              </ul>
            </div>
            <div class="p-2">
              <article
                class="flex w-full justify-content-between align-items-center mb-3 select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary">
                <div class="flex align-items-center">
              <span class="border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100">
                  <i class="pi pi-cog text-lg"></i>
              </span>
                  <div class="ml-2">
                    <p class="font-semibold text-sm mt-0 mb-1">System Settings</p>
                    <p class="font-normal text-xs text-600 mt-0 mb-0">Change your system preferences</p>
                  </div>
                </div>
                <span
                  class="hidden md:inline-block border-1 px-2 py-1 surface-border border-round surface-100 mr-2 text-600 text-xs">S</span>
              </article>
              <article
                class="flex w-full justify-content-between align-items-center mb-3 select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary">
                <div class="flex align-items-center">
              <span class="border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100">
                  <i class="pi pi-user text-lg"></i>
              </span>
                  <div class="ml-2">
                    <p class="font-semibold text-sm mt-0 mb-1">My Profile</p>
                    <p class="font-normal text-xs text-600 mt-0 mb-0">Go to your profile</p>
                  </div>
                </div>
                <span
                  class="hidden md:inline-block border-1 px-2 py-1 surface-border border-round surface-100 mr-2 text-600 text-xs">P</span>
              </article>

              <article
                class="flex w-full justify-content-between align-items-center mb-3 select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary">
                <div class="flex align-items-center">
              <span class="border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100">
                  <i class="pi pi-moon text-lg"></i>
              </span>
                  <div class="ml-2">
                    <p class="font-semibold text-sm mt-0 mb-1">Switch to Dark Mode</p>
                    <p class="font-normal text-xs text-600 mt-0 mb-0">Switch to dark mode save your eyes</p>
                  </div>
                </div>
                <span
                  class="hidden md:inline-block border-1 px-2 py-1 surface-border border-round surface-100 mr-2 text-600 text-xs">D</span>
              </article>

              <article
                class="flex w-full justify-content-between align-items-center select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary">
                <div class="flex align-items-center">
              <span class="border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100">
                  <i class="pi pi-sign-out text-lg"></i>
              </span>
                  <div class="ml-2">
                    <p class="font-semibold text-sm mt-0 mb-1">Log out</p>
                    <p class="font-normal text-xs text-600 mt-0 mb-0">End your session</p>
                  </div>
                </div>
              </article>
            </div>
          </p-dialog>
        </div>
      </div>
    </div>
  </div>
</div>
