import { Injectable } from "@angular/core";
import { DiscountCode, discountType } from "../models/discount-code";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {
  discountCodes: DiscountCode[] = [
    {
      id: "a089e343-851c-425a-b077-3a1f2a651ec6",
      discountCode: "dGVzdGdncnJl",
      typeDiscount: discountType.amount,
      discountValue: 200
    }
  ];

  constructor(private readonly http: HttpClient) { }

  getLocations() {
    return this.http.get("/api/locations");
  }

  getLocation(id: number) {
    return this.http.get(`/api/locations/${id}`);
  }

  getStorageContainers() {
    return this.http.get("/api/storageContainers");
  }

  getStorageContainer(id: number) {
    return this.http.get(`/api/storageContainers/${id}`);
  }

  getStoragesGroupsByLocation(locationId: number) {
    return this.http.get(`/api/storageContainers/getGroups/${locationId}`);
  }

  getStorageStatuses() {
    return Promise.resolve([
      { name: "Dostępny", value: 'available', backgroundColor: "#689f38"},
      { name: "Zarezerwowany", value: 'reserved', backgroundColor: "#fbc02d"},
      { name: "Zamówiony", value: 'booked', backgroundColor: "#2196f3"},
      { name: "Wynajęty", value: 'rented', backgroundColor: "#d32f2f"},
      { name: "Serwis", value: 'serviced', backgroundColor: "#64748b"},
      { name: "Wyłączony", value: 'disabled', backgroundColor: "#212121"},
    ]);
  }

  getStorageGroups() {
    return Promise.resolve([
      { id: 1, name: "Magazyn 7m2"},
      { id: 2, name: "Magazyn 14m2"},
      { id: 3, name: "Magazyn 29m2"},
    ]);
  }

  getReservations() {
    return this.http.get("/api/reservation");
  }

  getReservation(reservationId: number): Observable<any> {
    return this.http.get(`/api/reservation/${reservationId}`);
  }

  getAvailableStorageContainers(reservationData: any): Observable<any> {
    return this.http.get(`/api/storageContainers?location.id=${reservationData.location.id}&status=available`);
  }

  validateDiscountCode(discountCode: string) {
    let rows = this.discountCodes.filter((item) => item.discountCode == discountCode);
    let row = rows.shift();
    return Promise.resolve(
      row ? {valid: true, discount: row} : {valid: false, discount: null}
    );
  }
}
