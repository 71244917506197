import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Table } from "primeng/table";
import { Representative } from "../reservations/reservations.component";
import { CreatorService } from "../../shared/data/services/creator.service";
import { FetchDataService } from "../../shared/data/services/fetch-data.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'storage-containers',
  templateUrl: './storage-containers.component.html',
  styleUrl: './storage-containers.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class StorageContainersComponent implements OnInit {
  storageContainers!: any[];
  locationList!: any[];
  filteredLocations: any[] = [];

  selectedStorageContainers!: any[];

  representatives!: Representative[];

  loading: boolean = true;
  activityValues: number[] = [0, 100];
  storageStatuses: any[] = [];
  selectedStatus: any;
  filteredStatuses: any[] = [];
  storageGroups: any[] = [];
  showStorageContainersDialog: boolean = false;
  storageContainer: any = {};
  storageContainerAddForm: FormGroup;
  protected submitted: boolean = false;
  protected readonly console = console;

  constructor(
    private router: Router,
    private readonly creatorService: CreatorService,
    private readonly fetchDataService: FetchDataService,
    private readonly messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder
  ) {
    this.storageContainerAddForm = this.fb.group({
      storageNumber: [{ value: '', disabled: false }, [Validators.required]],
      status: [{ value: '', disabled: false }, [Validators.required]],
      group: [{ value: '', disabled: false }, [Validators.required]],
      location: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  ngOnInit() {

    this.fetchDataService.getStorageStatuses().then((statuses) => {
      this.storageStatuses = statuses;
    });

    this.fetchDataService.getStorageGroups().then((groups: any) => {
      this.storageGroups = groups;
    });

    this.fetchDataService.getStorageContainers().subscribe((response: any) => {
      if (response.response.statusCode === 200) {
        this.storageContainers = response.data.map((item: any) => {
          item.status = this.storageStatuses.find((status) => status.value === item.status);
          return item;
        });
      } else {
        throw new Error('Error fetching storage containers');
      }
    });

    this.fetchDataService.getLocations().subscribe((response: any) => {
      if (response.response.statusCode === 200) {
        this.locationList = response.data;
      } else {
        throw new Error('Error fetching locations');
      }
    });

    this.loading = false;
  }

  clear(table: Table) {
    table.clear();
  }

  searchInput(dt: Table, event: any) {
    // console.log(event);
    dt.filterGlobal(event.target.value, 'contains');
  }

  getSeverity(status: any) {
    // console.log(status);
    switch (status.value) {
      case 'available':
        return 'success';
      case 'reserved':
        return 'warning';
      case 'booked':
        return 'info';
      case 'rented':
        return 'danger';
      case 'serviced':
        return 'secondary';
      case 'disabled':
        return 'contrast';
      default:
        return undefined;
    }
  }

  getValueStatus(value: string) {
    const obj = this.storageStatuses.find((status) => status.value === value);
    return obj ? obj.name : '';
  }

  filterStatuses(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.storageStatuses as any[]).length; i++) {
      let storageStatus = (this.storageStatuses as any[])[i];
      if (storageStatus.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(storageStatus);
      }
    }

    this.filteredStatuses = filtered;
  }

  filterLocations(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.locationList.length; i++) {
      let location = this.locationList[i];
      if (location.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
        filtered.push(location);
      }
    }

    this.filteredLocations = filtered;
  }

  openRow(event: any, orderId: string) {
    // console.log("Open row");
    // console.log(event);
    if (
      typeof event.target.className == 'string' &&
      !event.target.className.includes('p-checkbox-box') &&
      !event.target.classList.contains("disableSelect")
    ) {
      // console.log(orderId);
      this.router.navigate(
        ['/panel/storageContainers/details'],
        {
          queryParams: {
            "id": orderId
          },
        }
      );
    }
  }

  hideDialog() {
    this.showStorageContainersDialog = false;
    this.submitted = false;
  }

  onSubmitDialog() {
    console.log("storageContainerAddForm");
    console.log(this.storageContainerAddForm);
    this.submitted = true;
    if (this.storageContainerAddForm.valid) {
      this.creatorService.createStorageContainer(this.storageContainerAddForm.value)
        .subscribe((response: any) => {
          if (response.response.statusCode === 201) {
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Storage container created' });
          } else if (
            response.response.statusCode === 400 ||
            response.response.statusCode === 500 ||
            response.response.statusCode === 503
          ) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating storage container' });
          }
          this.showStorageContainersDialog = false;
        });
    }
  }

  confirmDialog(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `
        Czy chcesz usunąć wybrane kontenery?<br/>
        <ul>
          ${this.selectedStorageContainers.map((item) => `<li>${item.storageNumber}</li>`).join(' ')}
        </ul>
      `,
      header: 'Usuwanie',
      acceptIcon: "fa-regular fa-trash-can mr-2",
      acceptButtonStyleClass: "p-button-success",
      rejectIcon: "fa-solid fa-circle-xmark mr-2",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.selectedStorageContainers.forEach((item) => {
          this.creatorService.deleteStorageContainer(item.id).subscribe((response: any) => {
            if (response.response.statusCode === 200) {
              this.storageContainers = this.storageContainers.filter((storageContainer) => storageContainer.id !== item.id);
              this.messageService.add({ severity: 'success', summary: 'Usunięto', detail: `Prawidłowo usunięto kontener ${item.storageNumber}.`, life: 4000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Błąd', detail: `Wystąpił błąd podczas usuwania kontenera ${item.storageNumber}.`, life: 4000 });
            }
          });
        });
      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Anulowanie', detail: 'Anulowano usuwanie.', life: 5000 });
      }
    });
  }
}
