import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CreatorService } from "../../shared/data/services/creator.service";
import { CartItem } from "../../shared/data/models/cart-item";
import { FileUpload, FileUploadHandlerEvent } from "primeng/fileupload";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PeselValidator } from "../../shared/data/validators/pesel.validator";
import { NipValidator } from "../../shared/data/validators/nip.validator";
import { RegonValidator } from "../../shared/data/validators/regon.validator";
import { KrsValidator } from "../../shared/data/validators/krs.validator";
import { Router } from "@angular/router";
import { interval, Subscription, takeWhile } from "rxjs";

interface CompanyType {
  id: number,
  name: string,
  requireKRS: boolean,
}

interface Country {
  name: string,
  code: string,
  filterKeys: string
}

@Component({
  selector: 'order-form',
  templateUrl: './order-form.component.html',
  styleUrl: './order-form.component.scss'
})
export class OrderFormComponent implements OnInit, OnDestroy {
  checked2: boolean;
  checked3: boolean;
  nextStep: boolean;
  selectedCompanyType!: CompanyType;
  companyTypes: CompanyType[] = [
    { id: 1, name: "JDG", requireKRS: false, },
    { id: 2, name: "Sp. z o.o.", requireKRS: true },
    { id: 3, name: "Sp. j.", requireKRS: true },
    { id: 4, name: "Sp. p.", requireKRS: true },
    { id: 5, name: "Sp. k.", requireKRS: true },
    { id: 6, name: "S.K.A.", requireKRS: true },
    { id: 7, name: "SA", requireKRS: true },
    { id: 8, name: "Fundacja", requireKRS: true },
  ];
  rentalPeriod: number;
  cartValue: number;
  paymentsCommissions: any;
  subjectType: string;
  citizenType: string;
  reservation: any = {};
  reservationDetails: CartItem[] = [];
  orderForm: FormGroup;
  formSentinel: any = {
    downloadContract: false,
    signedContract: false,
    submitted: false,
    paymentMethod: "",
    formId: null,
    waitingForPayment: false,
  };
  sessionForm: any = {};
  @ViewChild("signedContractField") signedContractField!: FileUpload;
  uploadedFiles: any[] = [];
  protected readonly console = console;
  private paymentWindowClosedSub!: Subscription;

  constructor(protected creatorService: CreatorService, private fb: FormBuilder, private router: Router) {
    this.checked2 = false;
    this.checked3 = false;
    this.nextStep = false;
    this.subjectType = '';
    this.citizenType = '';
    this.rentalPeriod = 1;
    this.cartValue = 0.00;

    this.orderForm = this.fb.group({
      email: [{ value: "", disabled: false }, [Validators.required, Validators.email]],
      // phoneNumber: [{ value: "", disabled: false }, [Validators.required]],
      // countryCode: [{ value: "", disabled: false }, [Validators.required]],
      phone: [{ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]{9,}$')]],
      subjectType: [{ value: null, disabled: false }, [Validators.required]],
      // subjectType = company
      companyName: [{ value: '', disabled: true }],
      companyType: [{ value: null, disabled: true }],
      nipNumber: [{ value: '', disabled: true }],
      // regonNumber: [{ value: '', disabled: true }],
      // krsNumber: [{ value: '', disabled: true }],
      // subjectType = person
      surNames: [{ value: '', disabled: true }],
      lastName: [{ value: '', disabled: true }],
      hasPESEL: [{value: null, disabled: true}, [Validators.required]],
      PESEL: [{ value: '', disabled: true }],
      passportNumber: [{ value: '', disabled: true }],
      addressName: [{ value: '', disabled: true }, [Validators.required]],
      addressNumber: [{ value: '', disabled: true }, [Validators.required]],
      addressApartment: [{ value: '', disabled: true }],
      postalCode: [{ value: '', disabled: true }, [Validators.required]],
      town: [{ value: '', disabled: true }, [Validators.required]],
      atos: [{ value: false, disabled: false }, [Validators.requiredTrue]],
      paf: [{ value: false, disabled: false }, [Validators.requiredTrue]],
      pas: [{ value: false, disabled: false }, [Validators.requiredTrue]],
      krdCheck: [{ value: false, disabled: false }],
      newsletterCheck: [{ value: false, disabled: false }],
    });
  }

  ngOnInit() {
    this.reservation = this.creatorService.getSessionStorageItem('reservationSession', {});
    this.reservationDetails = this.creatorService.getSessionStorageItem('reservationDetails', []);
    this.rentalPeriod = this.creatorService.getSessionStorageItem('rentalPeriod', 1);
    this.cartValue = this.calculateCart() * 1.23 + this.reservation?.totalCommission;
    this.sessionForm = this.creatorService.getSessionStorageItem("form", null);
    if (this.sessionForm) {
      Object.keys(this.sessionForm).forEach((key) => {
        if (key == "phone") {
          this.orderForm.get(key)?.setValue(this.sessionForm[key].number);
        } else if (key == "subjectData") {
          Object.keys(this.sessionForm[key]).forEach((subKey) => {
            this.orderForm.get(subKey)?.setValue(this.sessionForm[key][subKey]);
          });
        } else {
          this.orderForm.get(key)?.setValue(this.sessionForm[key]);
        }
      });
    }
    this.onChanges();
    this.countPaymentCommissions();
    console.log("orderForm");
    console.log(this.orderForm);
  }

  ngOnDestroy() {
    // Upewnij się, że subskrypcja jest zakończona, gdy komponent zostaje zniszczony
    if (this.paymentWindowClosedSub) {
      this.paymentWindowClosedSub.unsubscribe();
    }
  }

  onChanges(): void {
    this.orderForm.controls.subjectType.valueChanges.subscribe(val => {
      console.log("subjectTypeEnum valueChanges");
      console.log(val);
      if (val == "person") {
        // enable person fields
        this.orderForm.controls.hasPESEL.enable();
        this.orderForm.controls.hasPESEL.setValidators([Validators.required]);
        this.orderForm.controls.surNames.enable();
        this.orderForm.controls.surNames.setValidators([Validators.required]);
        this.orderForm.controls.lastName.enable();
        this.orderForm.controls.lastName.setValidators([Validators.required]);
        this.orderForm.controls.krdCheck.enable();
        this.orderForm.controls.krdCheck.setValidators([Validators.requiredTrue]);
        // disable company fields
        this.orderForm.controls.companyType.setValidators(null);
        this.orderForm.controls.companyType.disable();
        this.orderForm.controls.nipNumber.setValidators(null);
        this.orderForm.controls.nipNumber.disable();
        /*this.orderForm.controls.regonNumber.setValidators(null);
        this.orderForm.controls.regonNumber.disable();*/
        this.orderForm.controls.companyName.setValidators(null);
        this.orderForm.controls.companyName.disable();
        /*this.orderForm.controls.krsNumber.setValidators(null);
        this.orderForm.controls.krsNumber.disable();*/
      } else if (val == "company") {
        // enable company fields
        this.orderForm.controls.companyType.enable();
        this.orderForm.controls.companyType.setValidators([Validators.required]);
        this.orderForm.controls.nipNumber.enable();
        this.orderForm.controls.nipNumber.setValidators([Validators.required, /*NipValidator.isValid*/]);
        /*this.orderForm.controls.regonNumber.enable();
        this.orderForm.controls.regonNumber.setValidators([Validators.required, /!*RegonValidator.isValid*!/]);*/
        this.orderForm.controls.companyName.enable();
        this.orderForm.controls.companyName.setValidators([Validators.required]);
        this.orderForm.controls.krdCheck.setValidators(null);
        this.orderForm.controls.krdCheck.disable();
        // disable person fields
        this.orderForm.controls.hasPESEL.setValidators(null);
        this.orderForm.controls.hasPESEL.disable();
        this.orderForm.controls.surNames.setValidators(null);
        this.orderForm.controls.surNames.disable();
        this.orderForm.controls.lastName.setValidators(null);
        this.orderForm.controls.lastName.disable();
        this.orderForm.controls.PESEL.setValidators(null);
        this.orderForm.controls.PESEL.disable();
        this.orderForm.controls.passportNumber.setValidators(null);
        this.orderForm.controls.passportNumber.disable();
        this.orderForm.controls.krdCheck.setValidators(null);
        this.orderForm.controls.krdCheck.disable();
      }

      if (val != null) {
        this.orderForm.controls.addressName.enable();
        this.orderForm.controls.addressName.setValidators([Validators.required]);
        this.orderForm.controls.addressNumber.enable();
        this.orderForm.controls.addressNumber.setValidators([Validators.required]);
        this.orderForm.controls.addressApartment.enable();
        this.orderForm.controls.postalCode.enable();
        this.orderForm.controls.postalCode.setValidators([Validators.required]);
        this.orderForm.controls.town.enable();
        this.orderForm.controls.town.setValidators([Validators.required]);
      }

    });

    this.orderForm.controls.hasPESEL.valueChanges.subscribe(val => {
      if (val == 1) {
        // this.orderForm.controls['hasPESEL'].setValidators([Validators.required]);
        this.orderForm.controls.PESEL.enable();
        this.orderForm.controls.PESEL.setValidators([Validators.required, PeselValidator.isValid]);
        this.orderForm.controls.passportNumber.setValidators(null);
        this.orderForm.controls.passportNumber.disable();
      } else {
        // this.orderForm.controls['hasPESEL'].setValidators(null);
        this.orderForm.controls.PESEL.setValidators(null);
        this.orderForm.controls.PESEL.disable();
        this.orderForm.controls.passportNumber.enable();
        this.orderForm.controls.passportNumber.setValidators([Validators.required]);
      }

    });

    /*this.orderForm.controls.companyType.valueChanges.subscribe(val => {
      console.log("companyType valueChanges");
      console.log(val);
      if (val && !val.requireKRS) {
        this.orderForm.controls.krsNumber.setValidators(null);
        this.orderForm.controls.krsNumber.disable();
      } else {
        this.orderForm.controls.krsNumber.enable();
        this.orderForm.controls.krsNumber.setValidators([Validators.required, /!*KrsValidator.isValid*!/]);
      }
    });*/

    this.orderForm.valueChanges.subscribe(val => {
      if (this.formSentinel.submitted) {
        console.log("[orderForm] valueChanges");
        console.log(val);
        console.log("[orderForm] this");
        console.log(this.orderForm.getRawValue());
        this.formSentinel.submitted = false;
      }
    });
  }

  get phoneNumber(): FormControl {
    return this.orderForm.get('phoneNumber') as FormControl;
  }

  get countryCode(): FormControl {
    return this.orderForm.get('countryCode') as FormControl;
  }

  /**
   * name
   */
  public next(event: any) {
    // ToDo: naprawić walidację, w tym miejscu.
    if (this.orderForm.valid && this.signedContractField.files.length > 0) {
      // this.nextStep = true; // Przeniesione do II etapu.
      this.creatorService.finishReservation(this.sessionForm.id, {
        reservationStatus: "awaiting payment",
      }).subscribe((response: any) => {
        console.log("finishReservation");
        console.log(response);

        if (response.response.statusCode == 200) {
          // this.creatorService.clearSessionStorage(); // ToDo: do włączenia po testach;
          console.log("200 PATCH OK");
          this.router.navigate([`/creator/orderSummary/${this.reservation?.id}`]);

        } else if (response.statusCode == 400) {
          console.warn("Błąd: saveForm");
          console.error(response);
        }
      });
    }
  }

  countPaymentCommissions() {
    this.paymentsCommissions = {
      fakturownia: this.cartValue >= 4000.01 ? 4.95 : Math.ceil(this.cartValue / 1000) * 0.99,
      payu: 0.00,
      paypal: 0.00,
    };
  }

  public selectPayment(method: string = "") {
    this.formSentinel.paymentMethod = method;
  }

  /**
   * II Etap rezerwacji.
   * @param event
   */
  public async toPayment(event: any) {

    // ToDo: zakończenie rezerwacji -> PATCH
    this.creatorService.finishReservation(this.sessionForm.id, {
      reservationStatus: "awaiting payment",
    }).subscribe((response: any) => {
      console.log("finishReservation");
      console.log(response);

      if (response.response.statusCode == 200) {
        // this.creatorService.clearSessionStorage(); // ToDo: do włączenia po testach;
        console.log("200 PATCH OK");
        if (response.data.invoice.payment_url != null) {
          const paymentWindow = window.open('', '_blank');
          console.log(response.data.invoice.payment_url);
          if (paymentWindow) {
            paymentWindow.location.href = response.data.invoice.payment_url;
            this.startCheckingPaymentWindow(paymentWindow);
          }
        } else {
          console.error("Błąd: payment_url");
          console.log(response.data.invoice);
        }

      } else if (response.statusCode == 400) {
        console.warn("Błąd: saveForm");
        console.error(response);
      }
    });
  }

  calculateCart() {
    let sum = 0;
    this.reservationDetails.forEach((item) => {
      sum += item.quantity * (item.locationStoragesGroup?.nettoUnitPromoPrice != null ? item.locationStoragesGroup?.nettoUnitPromoPrice : item.locationStoragesGroup?.nettoUnitPrice);
    })
    return sum * this.rentalPeriod;
  }

  onUploadSignedContract($event: FileUploadHandlerEvent) {
    this.creatorService.uploadFile($event.files[0]).subscribe((response: any) => {
      console.log("uploadFile");
      console.log(response);
      if (response.response.statusCode == 201) {
        console.log("signedContract");
        console.log($event);
        this.formSentinel.signedContract = true;
        // this.signedContractField.files.push($event.files[0]);
        this.creatorService.saveForm(this.sessionForm?.id, {
          email: this.orderForm.get("email")?.value,
          phone: {
            number: this.orderForm.get("phone")?.value,
            countryCode: "",
          },
          subjectType: this.orderForm.get("subjectType")?.value,
          subjectData: {
            companyName: this.orderForm.get("companyName")?.value,
            nipNumber: this.orderForm.get("nipNumber")?.value,
            //regonNumber: this.orderForm.get("regonNumber")?.value,
            // krsNumber: this.orderForm.get("krsNumber")?.value,
            surNames: this.orderForm.get("surNames")?.value,
            lastName: this.orderForm.get("lastName")?.value,
            polishCitizen: this.orderForm.get("hasPESEL")?.value == 1,
            PESEL: this.orderForm.get("PESEL")?.value,
            passportNumber: this.orderForm.get("passportNumber")?.value,
          },
          addressName: this.orderForm.get("addressName")?.value,
          addressNumber: this.orderForm.get("addressNumber")?.value,
          addressApartment: this.orderForm.get("addressApartment")?.value,
          postalCode: this.orderForm.get("postalCode")?.value,
          town: this.orderForm.get("town")?.value,
          atos: this.orderForm.get("atos")?.value,
          paf: this.orderForm.get("paf")?.value,
          pas: this.orderForm.get("pas")?.value,
          krdCheck: this.orderForm.get("krdCheck")?.value,
          newsletterCheck: this.orderForm.get("newsletterCheck")?.value,
          contractFiles: response.data.id,
        }).subscribe((response: any) => {
          console.log("saveForm");
          console.log(response);
          if (response.response.statusCode == 201 || response.response.statusCode == 200) {
            console.log("saveForm HTTP: ", response.response.statusCode);
            console.log(response);
            this.creatorService.setSessionStorageItem("form", response.data);
            this.sessionForm = response.data;
            this.formSentinel.downloadContract = true;
          } else if (response.response.statusCode == 400) {
            console.warn("Błąd: saveForm");
            console.error(response);
          }
        });
      }
    });
  }

  onSelectFile($event: any) {
    console.log("onSelectFile");
    console.log(this.signedContractField);
    console.log($event);
    this.signedContractField.upload();
    // ToDo: brak w przypadku niepowodzenia wgrania pliku.
    /*this.signedContractField.uploadIcon = "fa-solid fa-floppy-disk";
    this.signedContractField.uploadStyleClass = "p-button-success";
    this.signedContractField.chooseStyleClass = "p-button-success";*/
  }

  onRemoveFile($event: any) {
    this.formSentinel.downloadContract = this.signedContractField.files.length > 0;
  }

  onSubmit() {
    console.log("onSubmit");
    console.log(this.orderForm);
    console.log(this.formSentinel);
    /*
    console.log("validators");
    Object.keys(this.orderForm.controls).forEach((ctrl) => {
      console.log(ctrl);
      console.log(this.orderForm.get(ctrl)?.errors);
    });
    */
    this.formSentinel.submitted = true;
    if (this.orderForm.invalid) {
      return;
    }

    this.saveForm();
  }

  clickDownloadContract() {
    if (this.orderForm.valid) {
      console.log("this.orderForm.valid");
      console.log(this.orderForm.valid);

      this.saveForm().subscribe((response: any) => {
        console.log("response");
        console.log(response);
        if (response.response.statusCode == 201 || response.response.statusCode == 200) {
          console.log("saveForm HTTP: ", response.response.statusCode);
          console.log(response);
          this.creatorService.setSessionStorageItem("form", response.data);
          this.sessionForm = response.data;
          this.formSentinel.downloadContract = true;
          window.open(`/api/templates/precontract/${this.reservation.id}`, "_blank");
        } else if (response.response.statusCode == 400) {
          console.warn("Błąd: saveForm");
          console.error(response);
        }
      });
    }
  }

  private startCheckingPaymentWindow(paymentWindow: Window) {
    this.formSentinel.waitingForPayment = true;
    this.paymentWindowClosedSub = interval(1000)
      .pipe(takeWhile(() => !paymentWindow.closed))
      .subscribe({
        next: () => {
          console.log("Zakładka płatności nadal otwarta...");
        },
        error: (err) => {
          this.nextStep = false;
          console.error(err);
        },
        complete: () => {
          console.log('Zakładka płatności została zamknięta.');
          setTimeout(() => {
            if (paymentWindow) {
              paymentWindow.close();
            }
            this.nextStep = false;
            this.router.navigate([`/creator/orderSummary/${this.reservation?.id}`]);
          }, 1000);
          this.formSentinel.waitingForPayment = false;
        }
      });

  }

  private saveForm() {
    return this.creatorService.saveForm(this.sessionForm?.id,{
      email: this.orderForm.get("email")?.value,
      phone: {
        number: this.orderForm.get("phone")?.value,
        countryCode: "",
      },
      subjectType: this.orderForm.get("subjectType")?.value,
      subjectData: {
        companyName: this.orderForm.get("companyName")?.value,
        nipNumber: this.orderForm.get("nipNumber")?.value,
        //regonNumber: this.orderForm.get("regonNumber")?.value,
        // krsNumber: this.orderForm.get("krsNumber")?.value,
        surNames: this.orderForm.get("surNames")?.value,
        lastName: this.orderForm.get("lastName")?.value,
        polishCitizen: this.orderForm.get("hasPESEL")?.value == 1,
        PESEL: this.orderForm.get("PESEL")?.value,
        passportNumber: this.orderForm.get("passportNumber")?.value,
      },
      addressName: this.orderForm.get("addressName")?.value,
      addressNumber: this.orderForm.get("addressNumber")?.value,
      addressApartment: this.orderForm.get("addressApartment")?.value,
      postalCode: this.orderForm.get("postalCode")?.value,
      town: this.orderForm.get("town")?.value,
      atos: this.orderForm.get("atos")?.value,
      paf: this.orderForm.get("paf")?.value,
      pas: this.orderForm.get("pas")?.value,
      krdCheck: this.orderForm.get("krdCheck")?.value,
      newsletterCheck: this.orderForm.get("newsletterCheck")?.value,
    });
  }
}
