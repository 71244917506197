<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom" [scrollable]="true" [autoHideButtons]="true">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-regular fa-file-lines mr-2 text-xl"></i>
            <span>Dane</span>
          </ng-template>
          <form [formGroup]="storageContainerDataForm" (ngSubmit)="onSubmitDataTab();">
            <div class="grid">
              <div class="col-12">
                <div class="px-1 py-3 md:px-2 lg:px-4">
                  <div class="grid">
                    <div class="col-12">
                      <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
                        <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
                          <div class="flex align-items-center">
                            <i class="fa-solid fa-cube text-2xl mr-3 text-500"></i>
                            <span class="text-3xl font-medium text-900">Magazyn</span>
                          </div>
                          <div class="mt-3 md:mt-0">
                            <button pButton pRipple label="Settings" class="p-button-outlined mr-3" icon="pi pi-cog"></button>
                            <button pButton pRipple label="Compose" icon="pi pi-plus"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="surface-card p-4 shadow-2 border-round">
                    <div class="grid formgrid p-fluid">
                      <div class="field mb-4 col-12" [ngClass]="{'md:col-6': selectedStatus?.name != 'Wynajęty', 'md:col-3': selectedStatus?.name ==
                      'Wynajęty'}">
                        <label for="storageCode" class="font-medium text-900">Numer magazynu:</label>
                        <input id="storageCode" type="text" pInputText formControlName="storageNumber">
                      </div>
                      <div class="field mb-4 col-12" [ngClass]="{'md:col-6': selectedStatus?.name != 'Wynajęty', 'md:col-3': selectedStatus?.name ==
                      'Wynajęty'}">
                        <label for="storageStatus" class="font-medium text-900">Status magazynu:</label>
                          <p-autoComplete [suggestions]="filteredStatuses" [dropdown]="true" [forceSelection]="true" [autoOptionFocus]="true"
                                          autocomplete="autocomplete" optionLabel="name" optionValue="value" formControlName="status"
                                          (completeMethod)="filterStatuses($event)" (onSelect)="selectedStatus = $event.value"
                                          [inputStyle]="{'color': selectedStatus?.backgroundColor, 'font-weight': '500'}" id="storageStatusDropDown" inputId="storageStatus"
                          >
                          <ng-template let-storageStatus pTemplate="item">
                            <div class="flex align-items-center gap-2 p-3" [ngStyle]="{'background-color': storageStatus.backgroundColor }">
                              <div>{{ storageStatus.name }}</div>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      </div>
                      @if (['Wynajęty'].includes(selectedStatus?.name)) {
                        <!-- ToDo: obsłużyć, gdy rezerwacja zostanie dodana ręcznie. -->
                        <div class="field mb-4 col-12 md:col-6">
                          <label for="" class="font-medium text-900">Aktualnie wynajmujący:</label>
                          <p-button iconPos="left" icon="fa-solid fa-share-from-square" [link]="true"
                                    label="Rezerwacja: # , wynajęty do: dd.mm.YYYY" class="p-button-text" [routerLink]="['']"
                          />
                        </div>
                      }
                      @if (['Wyłączony', 'Serwis'].includes(selectedStatus?.name)) {
                        <div class="field mb-4 col-12">
                          <label for="notes" class="font-medium text-900">Notatka do statusu:</label>
                          <p-editor id="notes" [style]="{ height: '120px' }"></p-editor>
                        </div>
                      }
                      <p-divider class="w-full mb-2"></p-divider>
                      <div class="field mb-4 col-12 md:col-6">
                        <label for="storageGroup" class="font-medium text-900">Grupa magazynu:</label>
                        <p-autoComplete [dropdown]="true" [forceSelection]="true" [autoOptionFocus]="true" autocomplete="autocomplete"
                                        [suggestions]="filteredStorageGroups" (completeMethod)="filterGroups($event)"
                                        optionLabel="name" formControlName="group" inputId="storageGroup"
                        >
                          <ng-template let-location pTemplate="item">
                            <div class="flex align-items-center">
                              <span>{{ location.name }}</span>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      </div>
                      <div class="field mb-4 col-12 md:col-6">
                        <label for="storageLocation" class="font-medium text-900">Lokalizacja:</label>
                        <p-autoComplete [dropdown]="true" [forceSelection]="true" [autoOptionFocus]="true" [suggestions]="filteredLocations"
                                        (completeMethod)="filterLocations($event)" autocomplete="autocomplete" optionLabel="name"
                                        formControlName="location" inputId="storageLocation"
                        >
                          <ng-template let-location pTemplate="item">
                            <div class="flex align-items-center">
                              <span>{{ location.name }}</span>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      </div>
                      <p-divider class="w-full"></p-divider>
                      <div *ngIf="this.messages.length > 0" class="col-12">
                        <p-messages [(value)]="messages" [enableService]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'" />
                      </div>
                      <div class="col-12">
                        <button type="submit" pButton pRipple icon="fa-solid fa-floppy-disk" iconPos="left" label="Zapisz zmiany" class="w-auto mt-3"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-clock-rotate-left mr-2 text-xl"></i>
            <span>Historia</span>
          </ng-template>
          <div class="card">
            <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
              <ng-template pTemplate="marker" let-event>
                <span class="shadow-2 flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1" [style.backgroundColor]="event.color">
                  <i [ngClass]="event.icon"></i>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <p-card [header]="event.status" [subheader]="event.date">
                  <img *ngIf="event.image" [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + event.image" [alt]="event.name" width="200" class="shadow-2" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate
                    neque quas!
                  </p>
                  <button pButton label="Read more" class="p-button-text"></button>
                </p-card>
              </ng-template>
            </p-timeline>
          </div>
        </p-tabPanel>
        <!-- <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-paperclip mr-2 text-xl"></i>
            <span>Zasoby</span>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <div class="px-1 py-3 md:px-2 lg:px-4">
                <div class="surface-card p-4 shadow-2 border-round">

                </div>
              </div>
            </div>
          </div>
        </p-tabPanel> -->
      </p-tabView>
    </div>
  </div>
</div>
